<template>
  <div class="page login">
    <div class="holder">
      <div class="inner-left">
        <img class="logo" src="../assets/img/login/logo-dark.png" alt="Logo dark" />
        <div class="content-holder">
          <hr />
          <h1>Login Portal</h1>
          <p>
            Welcome to the We Move Digital Platform! Please login with your Daimler account and enjoy the content we
            have created for you
          </p>
          <a class="cta-button" :href="url">Let’s log in!</a>
        </div>
      </div>
      <div class="inner-right"></div>
    </div>
    <div class="menu">
      <ul>
        <li>
          <router-link to="/legal">
            <span>{{$t('links.legal')}}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/privacy">
            <span>{{$t('links.privacy')}}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/cookies">
            <span> {{$t('links.cookies')}}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/contact">
            <span> {{$t('links.contact')}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      url: `${process.env.VUE_APP_API_URL}/api/auth/authorize`,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_fonts.scss";
@import "@/assets/scss/_defaults.scss";
.page.login {
  height: 100vh;
  .holder {
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;

    .inner-left {
      padding: 48px 120px;
      width: 50%;
      @include desktopxl-down {
        width: 100%;
        padding: 48px 32px;
      }

      .logo {
        max-width: 82px;
        position: absolute;
        top: 48px;
        left: 120px;
        @include desktopxl-down {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      .content-holder {
        max-width: 485px;
        @include desktopxl-down {
          margin: 0 auto;
        }

        hr {
          width: 72px;
          height: 4px;
          background: #000000;
          margin-left: 0;
        }

        h1 {
          font-size: 56px;
          font-family: $headingstack;
          color: #000000;
          font-weight: 600;
          margin: 16px 0;
        }
        p {
          font-size: 16px;
          color: #000000;
          font-family: $headingstack;
          line-height: 24px;
          margin-bottom: 32px;
        }
        .cta-button {
          display: block;
          background: #000000;
          border: none;
          color: #fff;
          padding: 12px 32px;
          max-width: 486px;
          width: 100%;
          text-align: left;
          font-weight: 500;
          position: relative;

          &:after {
            content: url("../assets/img/icons/arrow-right.svg");
            padding-right: 24px;
            line-height: 0;
            position: absolute;
            right: 0;
          }
          &:hover {
            background: #404040;
          }
        }
      }
    }
    .inner-right {
      background-image: url("../assets/img/login/bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      height: 100%;
      background-position: center;
      @include desktopxl-down {
        display: none;
      }
    }
  }
  .menu {
    position: absolute;
    bottom: 48px;
    left: 120px;

    @include desktopxl-down {
      right: 0;
      left: 0;
      margin: 0 auto;
    }

    ul {
      @include desktopxl-down {
        text-align: center;
      }
    }

    ul li {
      display: inline-block;
      color: #a4aaae;
      font-size: 14px;
      a {
        text-decoration: underline;
        &:hover {
          color: black;
        }
      }

      &:nth-child(1) {
        &:after {
          content: "|";
          padding: 0 10px;
        }
      }
      &:nth-child(2) {
        &:after {
          content: "|";
          padding: 0 10px;
        }
      }
      &:nth-child(3) {
        &:after {
          content: "|";
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
